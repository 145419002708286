import styled from 'styled-components';

const CustomRateStyle = styled.div`
	.rate-wrap {
		display: flex;
		align-items: flex-end;
	}

	.rated-count {
		color: var(--lightGreen);
		margin-left: var(--sp1-5x);
	}

	.ant-rate {
		color: var(--green);
		font-size: var(--h6);

		&.ant-rate-disabled {
			pointer-events: none;
		}
	}

	.ant-rate-disabled .ant-rate-star {
		cursor: default;
	}
	.ant-rate-disabled .ant-rate-star > div:hover {
		transform: scale(1);
	}
	.ant-rate-star {
		position: relative;
		display: inline-block;
		color: inherit;
		cursor: pointer;
	}
	.ant-rate-star > div {
		transition: all 0.3s, outline 0s;
	}

	.ant-rate-star {
		@media (hover: hover) {
			> div:hover {
				transform: scale(1.1);
			}
		}
	}

	.ant-rate-star > div:focus {
		outline: 0;
	}
	.ant-rate-star > div:focus-visible {
		outline: 1px dashed var(--green);
		transform: scale(1.1);
	}
	.ant-rate-star-first,
	.ant-rate-star-second {
		color: var(--lightGreen);
		transition: all 0.3s;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.ant-rate-star-first .anticon,
	.ant-rate-star-second .anticon {
		vertical-align: middle;
	}
	.ant-rate-star-first {
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		overflow: hidden;
		opacity: 0;
	}
	.ant-rate-star-half .ant-rate-star-first,
	.ant-rate-star-half .ant-rate-star-second {
		opacity: 1;
	}
	.ant-rate-star-half .ant-rate-star-first,
	.ant-rate-star-full .ant-rate-star-second {
		color: inherit;
	}
	.ant-rate-text {
		display: inline-block;
	}
	.ant-rate-rtl {
		direction: rtl;
	}
	.ant-rate-rtl .ant-rate-star:not(:last-child) {
		margin-right: 0;
	}
	.ant-rate-rtl .ant-rate-star-first {
		right: 0;
		left: auto;
	}
`;

export default CustomRateStyle;
