import styled from 'styled-components';

const ShopItemStyle = styled.div`
	--productUpperPadding: var(--sp2x);
	--productLowerPadding: 0 var(--sp2x) var(--sp3x) var(--sp2x);
	--productNameMarginTop: var(--sp1x);
	--productNameMarginBottom: var(--sp3x);
	--priceMarginTop: var(--sp2x);

	height: 100%;
	position: relative;

	.favorite {
		position: absolute;
		top: var(--sp3x);
		right: var(--sp3x);
		cursor: pointer;
	}

	.product-item-link {
		display: flex;
		flex-direction: column;
		height: 100%;

		.product-upper {
			padding: var(--productUpperPadding);
			background-color: var(--white);

			.upper-image {
				padding-top: 100%;
			}
		}

		.product-lower {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: var(--productLowerPadding);
			background-color: var(--white);
			height: 100%;

			.name {
				margin-top: var(--productNameMarginTop);
				margin-bottom: var(--productNameMarginBottom);
			}

			.product-rate {
				display: flex;
				align-items: center;
			}

			.price-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: var(--priceMarginTop);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--productUpperPadding: var(--sp2x);
		--productLowerPadding: 0 var(--sp2x) var(--sp3x) var(--sp2x);
		--productNameMarginBottom: var(--sp3x);
		--priceMarginTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--productUpperPadding: var(--sp2x);
		--productLowerPadding: 0 var(--sp2x) var(--sp3x) var(--sp2x);
		--productNameMarginBottom: var(--sp3x);
		--priceMarginTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--productUpperPadding: var(--sp1-5x);
		--productLowerPadding: 0 var(--sp1-5x) var(--sp2x) var(--sp1-5x);
		--productNameMarginBottom: var(--sp3x);
		--priceMarginTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--productUpperPadding: var(--sp1-5x);
		--productLowerPadding: 0 var(--sp1-5x) var(--sp2x) var(--sp1-5x);
		--productNameMarginBottom: var(--sp2x);
		--priceMarginTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--productUpperPadding: var(--sp1-5x);
		--productLowerPadding: 0 var(--sp1-5x) var(--sp2x) var(--sp1-5x);
		--productNameMarginBottom: var(--sp2x);
		--priceMarginTop: var(--sp2x);
	}
`;

export default ShopItemStyle;
