import styled from 'styled-components';

const PageStyle = styled.main`
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(68, 38, 99, 0.3);
		opacity: 0;
		transition: opacity var(--trTime) ease-out;
		z-index: 3;
		pointer-events: none;
	}
`;

export default PageStyle;
