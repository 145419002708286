import { Rate } from 'antd';

//* Components
import Text from '@/components/common/Text';

//* Style
import CustomRateStyle from './style';

const CustomRate = ({ className, value, onChange, defaultValue, hasLabel = true, disabled = true, qty = 0 }) => {
	return (
		<CustomRateStyle className={className || ''}>
			<div className='rate-wrap'>
				<Rate
					allowHalf
					value={value}
					disabled={disabled}
					onChange={onChange}
					defaultValue={Number(defaultValue)}
				/>

				{hasLabel && (
					<div className='rated-count'>
						<Text className={'p font-futura-regular lightGreen'}>{`(${qty})`}</Text>
					</div>
				)}
			</div>
		</CustomRateStyle>
	);
};

export default CustomRate;
