import { useCallback } from 'react';

//* Hoc's
import { withCartContext, withFavoriteContext } from '@/context';

//* Components
import Price from '../Price';
import Text from '@/components/common/Text';
import Icon from '@/components/common/Icon';
import Image from '@/components/common/Image';
import Button from '@/components/common/Button';
import CustomRate from '@/components/common/CustomRate';
import CustomLink from '@/components/common/CustomLink';

//* Style
import ShopItemStyle from './style';

const ShopItem = ({ id, image, name, category, url, price, sale_price, average_rating, toggleFavorite, checkActiveFavorite, toggleCartItem, review_count, stock_status }) => {
	//! Add to bag
	const handleAddToBag = useCallback((e) => {
		e.preventDefault();

		return toggleCartItem({ productId: id, qty: 1 });
	}, []);

	return (
		<ShopItemStyle>
			<CustomLink
				url={url}
				className={'product-item-link'}>
				<div className='product-upper'>
					<Image
						src={image.src}
						alt={image.alt}
						className={'upper-image'}
					/>
				</div>

				<div className='product-lower'>
					<div>
						<Text className={'p font-futura-regular'}>{category}</Text>

						<Text className={'h5 font-futura-medium name'}>{name}</Text>
					</div>

					<div>
						<CustomRate
							value={average_rating}
							className={'product-rate'}
							qty={review_count}
						/>
						<div className='price-wrap'>
							<Price
								price={price}
								sale_price={sale_price}
							/>

							{stock_status == 'instock' ? (
								<Button
									hasAdd
									size={'small'}
									text={'addToBag'}
									onClick={handleAddToBag}
								/>
							) : (
								<Button
									size={'small'}
									text={'outOfStock'}
									className={`out-of-stock`}
								/>
							)}
						</div>
					</div>
				</div>
			</CustomLink>

			<Icon
				className={'h2 favorite'}
				onClick={() => toggleFavorite({ productId: id })}
				name={`${checkActiveFavorite({ productId: id }) ? 'heart-fill' : 'heart'}`}>
				<span className='path1'></span>
				<span className='path2'></span>
			</Icon>
		</ShopItemStyle>
	);
};

export default withFavoriteContext(withCartContext(ShopItem, ['toggleCartItem']), ['toggleFavorite', 'checkActiveFavorite']);
