import { useEffect } from 'react';

//* Style
import PageStyle from './style';

const Page = ({ ...props }) => {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	return <PageStyle className={`page ${props.className || ''}`}>{props.children}</PageStyle>;
};

export default Page;
